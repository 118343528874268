import {
    Editor,
    Element, Node,
    Operation,
    Path, Text,
} from 'slate'
import { HistoryEditor } from 'slate-history'

import { SuggestionsEditor } from './editor'
import { IsInRedo, IsInUndo } from './maps'
import { OperationEx, SuggestionOperation } from './operation'
import { Join, Marker, Suggestion } from './suggestion'
import { markAsInsertSuggestion, shouldHaveInlines } from './util'

let NEXT_ID = 0

/**
 * Augment an editor with ability to record suggestions.
 *
 * This plugin should be located below any plugin which may modify or inject new
 * operations, and above any plugin which may reject, replay, or reverse
 * previous operations.
 */
export function withSuggestions<T extends Editor>(editor: T): T & SuggestionsEditor {
    const e = editor as T & SuggestionsEditor
    const { apply: baseApply, isVoid, isInline } = e

    e.editingMode = 'normal'
    e.isVoid = e => Marker.is(e) || isVoid(e)
    e.isInline = e => (Marker.is(e) && e.inline) || isInline(e)
    e.apply = apply.bind(null, baseApply, e)

    if (!('generateID' in e)) {
        (e as T & SuggestionsEditor).generateID = () => {
            const id = NEXT_ID
            NEXT_ID += 1
            return id.toString()
        }
    }

    if (HistoryEditor.isHistoryEditor(e)) {
        const { undo, redo } = e
        e.undo = () => {
            IsInUndo.set(e, true)
            undo()
            IsInUndo.set(e, false)
        }
        e.redo = () => {
            IsInRedo.set(e, true)
            redo()
            IsInRedo.set(e, false)
        }
    }

    return e
}

/**
 * Process an operation according to current state of suggestions in an editor.
 *
 * This function ensures that the lower `apply` is never called with an
 * operation crossing a suggestion boundary. Operations which do will be split
 * into smaller operations contained within suggestions.
 *
 * This function will also pre-process more complex suggestions to make them
 * work better with the base plugin, and other lower plugins. For example, when
 * removing a node this function will first reject all suggestions on that node.
 *
 * Finally it will, based on current editor state, determine whether
 * an operation is meant as a suggestion, and mark it appropriately.
 */
function apply(
    apply: (op: OperationEx) => void,
    editor: SuggestionsEditor,
    operation: Operation,
) {
    if (IsInUndo.get(editor) || IsInRedo.get(editor)) {
        return apply(operation)
    }

    if (SuggestionOperation.isSuggestionOperation(operation)) {
        return applyLow(apply, editor, operation)
    }

    /* istanbul ignore next */
    if (operation.suggestion != null || operation.change != null) {
        throw new Error(`${JSON.stringify(operation)} is not a valid suggestion operation`)
    }

    const op = operation as OperationEx
    const isSuggesting = SuggestionsEditor.isSuggesting(editor)

    // Mark operation as not-a-suggestion initially.
    op.suggestion = null

    switch (op.type) {
    case 'insert_node':
        // Empty text nodes may be inserted by normalization e.g.. between
        // adjacent inline nodes.
        if (Text.isText(op.node) && op.node.text === "") {
            applyLow(apply, editor, op)
            break
        }

        if (isSuggesting) {
            op.suggestion = 'change'
            op.change = 'insert'
        }

        applyLow(apply, editor, op)
        break

    case 'insert_text':
        if (isSuggesting && op.text !== '') {
            op.suggestion = 'change'
            op.change = 'insert'
        }
        applyLow(apply, editor, op)
        break

    case 'move_node': {
        if (isSuggesting) {
            op.suggestion = 'change'
            op.change = 'move'
            op.originalPath = op.path
        }

        applyLow(apply, editor, op)
        break
    }

    case 'remove_node': {
        if (Text.isText(op.node) && op.node.text === "") {
            applyLow(apply, editor, op)
            break
        }

        if (isSuggesting) {
            op.suggestion = 'change'
            op.change = 'remove'
        }

        applyLow(apply, editor, op)

        break
    }

    case 'remove_text': {
        if (isSuggesting && op.text !== '') {
            op.suggestion = 'change'
            op.change = 'remove'
        }
        const node = Node.get(editor, op.path)

        if (Suggestion.isRemove(node)) break

        applyLow(apply, editor, op)

        break
    }

    case 'merge_node': {
        const node = Node.get(editor, op.path)
        if (Text.isText(node)) {
            applyLow(apply, editor, op)
            break
        }

        if (isSuggesting) {
            op.suggestion = 'change'
            op.change = 'merge'
        }

        let lop = op
        for (;;) {
            const prevPath = Path.previous(op.path)
            const prev = Node.ancestor(editor, prevPath)

            applyLow(apply, editor, lop)

            if (op.path[op.path.length - 1] === 1 || !Suggestion.isRemove(prev)) break

            // Need to get() it again, as apply(lop) may have changed its
            // properties
            const { text, children, ...properties } = Node.get(editor, prevPath)
            lop = {
                ...op,
                path: prevPath,
                position: Node.ancestor(editor, Path.previous(prevPath)).children.length,
                properties,
            }
        }

        break
    }

    case 'split_node': {
        if (!isSuggesting) {
            applyLow(apply, editor, op)
            break
        }

        const node = Node.get(editor, op.path)
        if (Text.isText(node)) {
            applyLow(apply, editor, op)
            break
        }

        // When user presses enter at the beginning or the end of
        // a paragraph this is technically splitting the node, but logically
        // is closer to inserting a new paragraph and moving the cursor
        // there.
        const end = node.children.length - 1
        const last = node.children[end]
        const isAtStart = op.position === 0
            || (op.position === 1 && Text.isText(node.children[0]) && node.children[0].text === "")
        const isAtEnd = op.position === node.children.length
            || (op.position === end && Text.isText(last) && last.text === "")
        if (isAtStart || isAtEnd) {
            applyLow(apply, editor, op)
            applyLow(apply, editor, {
                type: 'set_node',
                properties: { suggestion: undefined },
                newProperties: { suggestion: 'insert', movedFrom: undefined, splitFrom: undefined },
                path: isAtStart ? op.path : Path.next(op.path),
            })
            break
        }

        op.suggestion = 'change'
        op.change = 'split'
        applyLow(apply, editor, op)
        break
    }

    case 'set_selection':
    case 'set_node': // TODO: what to do with set_node?
        applyLow(apply, editor, op)
        break
    }
}

function applyLow(
    apply: (op: Operation) => void,
    editor: SuggestionsEditor,
    op: OperationEx,
) {
    if (op.suggestion == null) {
        // When op is not a suggestion operation we defer to Slate's default
        // handling.
        apply(op)
    } else {
        // Otherwise we process it ourselves.
        Editor.withoutNormalizing(editor, () => {
            transform(apply, editor, OperationEx.normalize(op) as unknown as SuggestionOperation)
        })
    }
}

/**
 * Transform a {@link BaseEditor} by a suggestion operation.
 */
function transform(
    apply: (op: Operation) => void,
    editor: SuggestionsEditor,
    op: SuggestionOperation,
): void {
    switch (op.type) {
    case 'insert_node':
        switch (op.suggestion) {
        case 'change':
            apply({
                ...op,
                node: markAsInsertSuggestion(op.node),
            })
            break

        case 'accept':
            apply({
                type: 'set_node',
                path: op.path,
                properties: {
                    suggestion: 'insert',
                },
                newProperties: {
                    suggestion: undefined,
                },
            })
            break

        case 'reject':
            apply({
                ...op,
                type: 'remove_node',
                node: {
                    ...op.node,
                    suggestion: 'insert',
                },
            })
            break
        }
        break

    case 'insert_text':
        switch (op.suggestion) {
        case 'change': {
            const node = Node.leaf(editor, op.path)

            // A: updating an insert suggestion
            if (Suggestion.isInsert(node)) {
                apply(op)
                break
            }

            // B: re-use existing node when possible
            if (node.text === "") {
                apply(op)
                apply({
                    type: 'set_node',
                    path: op.path,
                    properties: { suggestion: undefined },
                    newProperties: { suggestion: 'insert' },
                })
                break
            }

            const { text: _text, ...properties } = node

            // C: since we cannot insert text into node (as it is not an insert
            // suggestion, see A), we may need to split it to make room for
            // the new suggestion text node.
            if (op.offset === 0 || op.offset === node.text.length) {
                apply({
                    type: 'split_node',
                    path: op.path,
                    position: op.offset,
                    properties,
                })
            } else {
                // The same operation two times, to create three text fragments
                apply({
                    type: 'split_node',
                    path: op.path,
                    position: op.offset,
                    properties,
                })
                apply({
                    type: 'split_node',
                    path: op.path,
                    position: op.offset,
                    properties,
                })
            }

            const path = op.offset > 0 ? Path.next(op.path) : op.path
            apply({
                type: 'set_node',
                path,
                properties: { suggestion: node.suggestion },
                newProperties: { suggestion: 'insert' },
            })
            apply({
                ...op,
                path,
                offset: 0,
            })

            break
        }

        case 'accept': {
            const node = Node.leaf(editor, op.path)

            // Make a sanity check
            /* istanbul ignore next */
            if (!Suggestion.isInsert(node)) {
                throw new Error("cannot reject invalid suggestion")
            }

            const { text, ...properties } = node

            if (op.offset + op.text.length < node.text.length) {
                apply({
                    type: 'split_node',
                    path: op.path,
                    position: op.offset + op.text.length,
                    properties,
                })
            }

            if (op.offset > 0) {
                apply({
                    type: 'split_node',
                    path: op.path,
                    position: op.offset,
                    properties,
                })
            }

            apply({
                type: 'set_node',
                path: op.offset > 0 ? Path.next(op.path) : op.path,
                properties: { suggestion: 'insert' },
                newProperties: { suggestion: undefined },
            })

            break
        }

        case 'reject': {
            const node = Node.leaf(editor, op.path)

            // Make a sanity check
            /* istanbul ignore next */
            if (!Suggestion.isInsert(node)) {
                throw new Error("cannot reject invalid suggestion")
            }

            if (op.text === node.text) {
                const parent = Node.parent(editor, op.path)
                const nextIndex = op.path[op.path.length - 1] + 1
                const next = nextIndex >= parent.children.length
                    ? null
                    : parent.children[nextIndex]

                // Suggestion node was inserted after an existing text node
                if (Text.isText(next)) {
                    apply({
                        type: 'remove_node',
                        path: op.path,
                        node,
                    })
                    break
                }
            }

            apply({ ...op, type: 'remove_text' })

            if (op.text === node.text) {
                apply({
                    type: 'set_node',
                    path: op.path,
                    properties: { suggestion: 'insert' },
                    newProperties: { suggestion: undefined },
                })
            }

            break
        }
        }
        break

    case 'merge_node':
        switch (op.suggestion) {
        case 'change': {
            const node = Node.get(editor, op.path)

            if (Text.isText(node)) {
                apply(op)
                break
            }
            if (Suggestion.is(node)) {
                op.properties.suggestion = node.suggestion
            }

            const prevPath = Path.previous(op.path)
            const prev = Node.ancestor(editor, prevPath)

            // Don't allow merging markers
            if (Marker.is(prev) || Marker.is(node)) break

            if (Suggestion.isSplit(node)) {
                const end = prev.children.length - 1
                const markerInx = Text.isText(prev.children[end]) && prev.children[end].text === ""
                    ? end - 1
                    : end
                const marker = prev.children[markerInx]

                // Inverse of a previous split_node operation
                if (Marker.isSplit(marker) && node.splitFrom === marker.id) {
                    op.position -= 2
                    transform(apply, editor, {
                        type: 'split_node',
                        path: prevPath,
                        position: markerInx,
                        properties: op.properties,
                        suggestion: 'reject',
                        change: 'split',
                    })
                    break
                }

                /* istanbul ignore next */
                break
            }

            const { children: _, ...properties } = node
            const marker: Join = {
                suggestion: 'marker',
                type: 'join',
                inline: shouldHaveInlines(editor, prev),
                properties,
                children: [{ text: '' }],
            }

            if (Suggestion.isMove(node)) {
                marker.mergedFrom = node.movedFrom
                delete marker.properties.movedFrom
                delete marker.properties.suggestion
            }

            // Without this check it would be possible to merge non-removed text
            // into removed text, silently turning it into removed text (and the
            // same with insertions).
            if (Suggestion.isText(prev) && node.suggestion !== prev.suggestion) {
                marker.originalSuggestion = prev.suggestion
                apply({
                    type: 'set_node',
                    path: prevPath,
                    properties: { suggestion: prev.suggestion },
                    newProperties: { suggestion: undefined },
                })
                // Transfer suggestion to child nodes
                for (let inx = 0; inx < prev.children.length; ++inx) {
                    if (!Suggestion.is(prev.children[inx])) {
                        apply({
                            type: 'set_node',
                            path: [...prevPath, inx],
                            properties: { suggestion: undefined },
                            newProperties: { suggestion: prev.suggestion },
                        })
                    }
                }
            }

            // Transfer suggestion from `node` to its children. Without this
            // suggestions on merged nodes would be lost.
            if (Suggestion.isText(node)) {
                for (let inx = 0; inx < node.children.length; ++inx) {
                    if (!Suggestion.is(prev.children[inx])) {
                        apply({
                            type: 'set_node',
                            path: [...op.path, inx],
                            properties: { suggestion: undefined },
                            newProperties: { suggestion: node.suggestion },
                        })
                    }
                }
            }

            apply({
                type: 'insert_node',
                path: [...prevPath, prev.children.length],
                node: marker,
            })

            apply({ ...op, position: op.position + 1 })

            break
        }

        case 'accept': {
            const realPath = Path.previous(op.path)
            const node = Node.get(editor, realPath)

            /* istanbul ignore next */
            if (Text.isText(node)) {
                throw new Error("should never be reached")
            }

            const marker = node.children[op.position]

            /* istanbul ignore next */
            if (!Marker.isJoin(marker)) {
                throw new Error("merge suggestion contains no join marker")
            }

            apply({
                type: 'remove_node',
                path: [...realPath, op.position],
                node: marker,
            })
            break
        }

        case 'reject': {
            const realPath = Path.previous(op.path)
            const node = Node.get(editor, realPath)

            if (Text.isText(node)) {
                apply(Operation.inverse(op))
                break
            }

            const marker = node.children[op.position]

            if (!Marker.isJoin(marker)) {
                throw new Error("merge suggestion contains no join marker")
            }

            const properties = {
                ...op.properties,
                ...marker.properties,
            }

            if (marker.mergedFrom != null) {
                properties.movedFrom = marker.mergedFrom
                properties.suggestion = 'move'
            }

            apply({
                type: 'remove_node',
                path: [...realPath, op.position],
                node: marker,
            })
            apply({
                type: 'split_node',
                path: realPath,
                position: op.position,
                properties,
            })

            break
        }
        }
        break

    case 'move_node':
        switch (op.suggestion) {
        case 'change': {
            const node = Node.get(editor, op.path)

            if (Suggestion.is(node)) {
                switch (node.suggestion) {
                case 'remove':
                    break

                // Moving an insert suggestion is the same as suggesting
                // directly at the new location.
                // TODO: support case where one user suggest insertion, and
                // another suggests move
                case 'insert':
                case 'move':
                    op.change = undefined as unknown as "move"
                    op.suggestion = undefined as unknown as "change"
                    apply(op)
                    // fallthrough

                default:
                    // Skip rest of this case.
                    return
                }
            }

            /* istanbul ignore next */
            if (Marker.is(node)) {
                break
            }

            const newPath = Editor.pathRef(editor, op.newPath)
            const markerPath = Path.next(op.path)
            const id = editor.generateID()

            apply({
                type: 'insert_node',
                path: markerPath,
                node: {
                    suggestion: 'marker',
                    type: 'move-source',
                    inline: shouldHaveInlines(editor, Node.parent(editor, op.path)),
                    children: [{ text: '' }],
                    id,
                },
            })

            apply({
                type: 'set_node',
                path: op.path,
                properties: {
                    suggestion: node.suggestion,
                    movedFrom: undefined,
                },
                newProperties: {
                    suggestion: node.suggestion ?? 'move',
                    movedFrom: id,
                },
            })

            apply({
                type: 'move_node',
                path: op.path,
                newPath: newPath.unref()!,
            })

            break
        }

        case 'accept': {
            const markerPath = Path.transform(Path.next(op.path), op)!
            const marker = Node.get(editor, markerPath)
            const nodePath = op.newPath
            const node = Node.get(editor, nodePath)

            /* istanbul ignore next */
            if (!Marker.isMoveSource(marker) || !Suggestion.isMove(node)
            || node.movedFrom !== marker.id) {
                throw new Error("cannot reject invalid move suggestion")
            }

            apply({
                type: 'set_node',
                path: nodePath,
                properties: {
                    suggestion: node.suggestion,
                    movedFrom: node.movedFrom,
                },
                newProperties: {
                    suggestion: node.suggestion === 'move' ? undefined : node.suggestion,
                    movedFrom: undefined,
                },
            })
            apply({
                type: 'remove_node',
                path: markerPath,
                node: marker,
            })
            break
        }

        case 'reject': {
            const markerPath = Path.transform(Path.next(op.path), op)!
            const marker = Node.get(editor, markerPath)
            const node = Node.get(editor, op.newPath)

            /* istanbul ignore next */
            if (!Marker.isMoveSource(marker) || !Suggestion.isMove(node)
            || node.movedFrom !== marker.id) {
                throw new Error("cannot reject invalid move suggestion")
            }

            apply({
                type: 'remove_node',
                path: markerPath,
                node: marker,
            })
            apply(Operation.inverse(op))
            apply({
                type: 'set_node',
                path: op.path,
                properties: {
                    suggestion: node.suggestion,
                    movedFrom: node.movedFrom,
                },
                newProperties: {
                    suggestion: node.suggestion === 'move' ? undefined : node.suggestion,
                    movedFrom: undefined,
                },
            })
            break
        }
        }
        break

    case 'remove_node':
        switch (op.suggestion) {
        case 'change': {
            const node = Node.get(editor, op.path)

            if (Suggestion.isInsert(node)) {
                apply(op)
                break
            }

            if (Marker.is(node)) {
                break
            }

            // When a normalization removed a node we must respect it -
            // otherwise that normalization will keep trying to remove the node
            // and crash the editor.
            if (Editor.isApplyingNormalizations(editor)) {
                // However, we cannot just allow removing any content. As
                // a compromise we'll allow removing empty nodes (we assume that
                // a working normalization also wouldn't want to remove content,
                // and thus any nodes it removes must be empty).
                /* istanbul ignore next */
                if ((Element.isElement(node) && !Editor.isEmpty(editor, node))
                || node.text === '') {
                    throw new Error('cannot remove non-empty node')
                }

                apply(op)

                if (Suggestion.isMove(node)) {
                    const [[marker, markerPath]] = Editor.nodes(editor, {
                        at: [],
                        match: n => Marker.isMoveSource(n) && n.id === node.movedFrom,
                    })

                    apply({
                        type: 'remove_node',
                        path: markerPath,
                        node: marker,
                    })
                }

                if (Suggestion.isSplit(node)) {
                    const [[marker, markerPath]] = Editor.nodes(editor, {
                        at: [],
                        match: n => Marker.isSplit(n) && n.id === node.splitFrom,
                    })

                    apply({
                        type: 'remove_node',
                        path: markerPath,
                        node: marker,
                    })
                }

                break
            }

            if (Suggestion.is(node)) {
                break
            }

            apply({
                type: 'set_node',
                path: op.path,
                properties: {
                    suggestion: undefined,
                },
                newProperties: {
                    suggestion: 'remove',
                },
            })
            break
        }

        case 'accept':
            apply({
                ...op,
                node: {
                    ...op.node,
                    suggestion: 'remove',
                },
            })
            break

        case 'reject':
            apply({
                type: 'set_node',
                path: op.path,
                properties: {
                    suggestion: 'remove',
                },
                newProperties: {
                    suggestion: undefined,
                },
            })
            break
        }
        break

    case 'remove_text':
        switch (op.suggestion) {
        case 'change': {
            const node = Node.leaf(editor, op.path)

            // A: updating an insert suggestion
            if (Suggestion.isInsert(node)) {
                apply(op)
                break
            }

            // B: handle double deletion
            if (Suggestion.isRemove(node)) {
                break
            }

            const { text: _text, ...properties } = node

            // C: since we cannot remove text from node, we may need to split it
            // to make room for the new suggestion text node.
            if (op.offset > 0 || op.text !== node.text) {
                if (op.offset + op.text.length < node.text.length) {
                    apply({
                        type: 'split_node',
                        path: op.path,
                        position: op.offset + op.text.length,
                        properties,
                    })
                }
                if (op.offset > 0) {
                    apply({
                        type: 'split_node',
                        path: op.path,
                        position: op.offset,
                        properties,
                    })
                }
            }

            const path = op.offset > 0 ? Path.next(op.path) : op.path

            // Sanity check
            /* istanbul ignore next */
            if (Node.leaf(editor, path).text !== op.text) {
                throw new Error("cannot remove text not present in the document")
            }

            apply({
                type: 'set_node',
                path,
                properties: { suggestion: undefined },
                newProperties: { suggestion: 'remove' },
            })
            break
        }

        case 'accept': {
            const node = Node.leaf(editor, op.path)

            // Make a sanity check
            /* istanbul ignore next */
            if (!Suggestion.isRemove(node)) {
                throw new Error("cannot accept invalid suggestion")
            }

            if (op.text === node.text) {
                apply({
                    type: 'remove_node',
                    path: op.path,
                    node,
                })
                break
            }

            apply(op)
            break
        }

        case 'reject': {
            const node = Node.leaf(editor, op.path)

            if (Suggestion.isInsert(node)) {
                const inversOp = Operation.inverse(op)
                apply(inversOp)
                break
            }

            // Make a sanity check
            /* istanbul ignore next */
            if (!Suggestion.isRemove(node)) {
                throw new Error("cannot reject invalid suggestion")
            }

            const { text: _text, ...properties } = node

            if (op.offset > 0 || op.text !== node.text) {
                if (op.offset + op.text.length < node.text.length) {
                    apply({
                        type: 'split_node',
                        path: op.path,
                        position: op.offset + op.text.length,
                        properties,
                    })
                }
                if (op.offset > 0) {
                    apply({
                        type: 'split_node',
                        path: op.path,
                        position: op.offset,
                        properties,
                    })
                }
            }

            const path = op.offset > 0 ? Path.next(op.path) : op.path
            apply({
                type: 'set_node',
                path,
                properties: { suggestion: 'remove' },
                newProperties: { suggestion: undefined },
            })

            break
        }
        }
        break

    case 'split_node':
        switch (op.suggestion) {
        case 'change': {
            const node = Node.get(editor, op.path)

            // Inverse of a previous merge_node operation
            if (Element.isElement(node)) {
                if (Marker.isJoin(node.children[op.position - 1])
                || Marker.isJoin(node.children[op.position])) {
                    if (!Marker.isJoin(node.children[op.position])
                    && Marker.isJoin(node.children[op.position - 1])) {
                        op.position -= 1
                    }

                    transform(apply, editor, {
                        type: 'merge_node',
                        path: Path.next(op.path),
                        position: op.position,
                        properties: op.properties,
                        change: 'merge',
                        suggestion: 'reject',
                    })
                    break
                }
                // Slate's high-level break handler will first split the text
                // node containing selection. This may produce
                //     <text/> <cursor> <text empty/> <marker/>
                // if cursor was just before the marker, ...
                if (Text.isText(node.children[op.position])
                && node.children[op.position].text === ''
                && Marker.isJoin(node.children[op.position + 1])) {
                    op.position += 1
                    transform(apply, editor, {
                        type: 'merge_node',
                        path: Path.next(op.path),
                        position: op.position,
                        properties: op.properties,
                        change: 'merge',
                        suggestion: 'reject',
                    })
                    break
                }
                // ... or
                //     <marker/> <text empty/> <cursor/> <text/>
                // if cursor was just after the marker.
                if (Text.isText(node.children[op.position - 1])
                && node.children[op.position - 1].text === ''
                && Marker.isJoin(node.children[op.position - 2])) {
                    op.position -= 2
                    transform(apply, editor, {
                        type: 'merge_node',
                        path: Path.next(op.path),
                        position: op.position,
                        properties: op.properties,
                        change: 'merge',
                        suggestion: 'reject',
                    })
                    break
                }
            }

            apply(op)

            // Splitting of a text node has no semantic significance so we don't
            // track it. Splitting of an insert suggestion has the same result
            // as making two separate insert suggestions, so we don't track that
            // either.
            if (Text.isText(node) || Suggestion.isInsert(node)) break

            const id = editor.generateID()
            const inline = shouldHaveInlines(editor, Node.get(editor, op.path))

            apply({
                type: 'insert_node',
                path: [...op.path, op.position],
                node: {
                    suggestion: 'marker',
                    type: 'split-point',
                    inline,
                    children: [{ text: '' }],
                    id,
                },
            })

            // All inlines must be followed by a text node. We insert it
            // manually rather than relying on normalization to avoid it being
            // marked as an insert suggestion.
            if (inline) {
                apply({
                    type: 'insert_node',
                    path: [...op.path, op.position + 1],
                    node: { text: '' },
                })
            }

            apply({
                type: 'set_node',
                path: Path.next(op.path),
                properties: {
                    splitFrom: undefined,
                },
                newProperties: {
                    splitFrom: id,
                },
            })

            break
        }

        case 'accept': {
            const node = Node.get(editor, op.path)

            // Splitting of a text node has no semantic significance so we don't
            // track it. Splitting of an insert suggestion has the same result
            // as making two separate insert suggestions, so we don't track that
            // either.
            if (Text.isText(node) || Suggestion.isInsert(node)) break

            const marker = node.children[op.position]
            /* istanbul ignore next */
            if (!Marker.isSplit(marker)) {
                throw new Error("split_node suggestion has no split-point marker")
            }

            const [otherPartEntry] = Editor.nodes(editor, {
                at: [],
                match: n => Suggestion.isSplit(n) && n.splitFrom === marker.id,
            })

            /* istanbul ignore next */
            if (otherPartEntry == null) {
                throw new Error(`no split_node suggestion for marker ${
                    JSON.stringify([...op.path, op.position])}`)
            }

            const [otherPart, otherPartPath] = otherPartEntry

            apply({
                type: 'set_node',
                path: otherPartPath,
                properties: {
                    splitFrom: otherPart.splitFrom,
                },
                newProperties: {
                    splitFrom: undefined,
                },
            })

            apply({
                type: 'remove_node',
                path: [...op.path, op.position],
                node: marker,
            })

            break
        }

        case 'reject': {
            const node = Node.get(editor, op.path)
            const nextPath = Path.next(op.path)
            const next = Node.get(editor, nextPath)

            // Splitting of a text node has no semantic significance so we don't
            // track it. Splitting of an insert suggestion has the same result
            // as making two separate insert suggestions, so we don't track that
            // either.
            if (Text.isText(node) || Suggestion.isInsert(node)) {
                apply(Operation.inverse(op))
                break
            }

            const marker = node.children[op.position]

            /* istanbul ignore next */
            if (!Suggestion.isSplit(next)) {
                throw new Error("cannot reject a split_node suggestion when"
                    + " there are other nodes in between the parts. Reject these"
                    + " suggestions first")
            }

            const last = node.children[node.children.length - 1]
            if (Text.isText(last) && last.text === "") {
                apply({
                    type: 'remove_node',
                    path: [...op.path, node.children.length - 1],
                    node: last,
                })
            }

            // Sanity check
            if (op.position === node.children.length) {
                throw new Error(`invalid suggestion: op.position (${op.position}) !== `
                    + `node.children.length ${node.children.length}`)
            }

            const { children, ...nextProperties } = next

            apply({
                type: 'remove_node',
                path: [...op.path, op.position],
                node: marker,
            })
            apply({
                type: 'merge_node',
                path: nextPath,
                position: op.position,
                properties: nextProperties,
            })
            break
        }
        }
        break

    /* istanbul ignore next */
    default:
        throw new Error(`${JSON.stringify(op)} is not a valid suggestion operation`)
    }
}
