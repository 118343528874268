import isPlainObject from 'is-plain-object'
import { Element } from 'slate'

export interface InsertSuggestion {
    suggestion: 'insert'
}

export interface RemoveSuggestion {
    suggestion: 'remove'
}

export interface MoveSuggestion {
    suggestion: 'move'
    movedFrom: string
}

export interface SplitSuggestion {
    splitFrom: string
}

export type Suggestion = InsertSuggestion | RemoveSuggestion | MoveSuggestion

export const Suggestion = {
    /** Check if value is a `Suggestion` object */
    is(this: void, value: unknown): value is Suggestion {
        const s = value as Suggestion
        return isPlainObject(value)
            && (s.suggestion === 'insert' || s.suggestion === 'remove' || s.suggestion === 'move')
    },

    /** Check if value is an `InsertSuggestion` object */
    isInsert<T extends object>(this: void, value: T): value is T & InsertSuggestion {
        return isPlainObject(value)
            && (value as unknown as InsertSuggestion).suggestion === 'insert'
    },

    /** Check if value is a `RemoveSuggestion` object */
    isRemove<T extends object>(this: void, value: T): value is T & RemoveSuggestion {
        return isPlainObject(value)
            && (value as unknown as RemoveSuggestion).suggestion === 'remove'
    },

    /**
     * Check if value is either an `InsertSuggestion` or a `RemoveSuggestion`
     * object
     */
    isText<T extends object>(
        this: void,
        value: T,
    ): value is T & (InsertSuggestion | RemoveSuggestion) {
        return isPlainObject(value) && (
            (value as unknown as InsertSuggestion).suggestion === 'insert'
            || (value as unknown as RemoveSuggestion).suggestion === 'remove')
    },

    isMove<T extends object>(this: void, value: T): value is T & MoveSuggestion {
        return isPlainObject(value)
            && typeof (value as unknown as MoveSuggestion).movedFrom === 'string'
    },

    isSplit<T extends object>(this: void, value: T): value is T & SplitSuggestion {
        return isPlainObject(value)
            && typeof (value as unknown as SplitSuggestion).splitFrom === 'string'
    },
}

export interface AbstractMarker extends Element {
    suggestion: 'marker'
    inline: boolean
}

export interface MoveSource extends AbstractMarker {
    type: 'move-source'
    id: string
}

export interface Join extends AbstractMarker {
    type: 'join'
    properties: Record<string, unknown>
    /** Before the merge operation the target node was a suggestion */
    originalSuggestion?: 'insert' | 'remove'
    mergedFrom?: string
}

export interface Split extends AbstractMarker {
    type: 'split-point'
    id: string
}

export type Marker = MoveSource | Join | Split

export const Marker = {
    /** Check if value is a `Marker` object */
    is(this: void, value: unknown): value is Marker {
        return isPlainObject(value) && (value as AbstractMarker).suggestion === 'marker'
    },

    isMoveSource(this: void, value: unknown): value is MoveSource {
        return Marker.is(value) && value.type === 'move-source'
    },

    isJoin(this: void, value: unknown): value is Join {
        return Marker.is(value) && value.type === 'join'
    },

    isSplit(this: void, value: unknown): value is Split {
        return Marker.is(value) && value.type === 'split-point'
    },
}
