import * as Slate from 'slate'

// ------------------------------------------------------ change suggestions ---

/**
 * Suggest insertion of a node or a text fragment
 */
export type InsertSuggestion = (Slate.InsertNodeOperation | Slate.InsertTextOperation) & {
    suggestion: 'change',
    change: 'insert',
}

/**
 * Suggest removal of a node
 */
export type RemoveNodeSuggestion = Slate.RemoveNodeOperation & {
    suggestion: 'change',
    change: 'remove',
    /**
     * The suggestion which was applied to the removed node.
     *
     * The only possible variant is `move`, as removing a remove suggestion is a
     * no-op, and removing an insert suggestion is handled as a rejection
     * instead.
     */
    originalSuggestion?: 'move',
}

/**
 * Suggest removal of a text fragment
 */
export type RemoveTextSuggestion = Slate.RemoveTextOperation & {
    suggestion: 'change',
    change: 'remove',
    /**
     * Does applying this operation require splitting node at `offset`?
     */
    splitBefore?: boolean,
    /**
     * Does applying this operation require splitting node at
     * `offset + text.length`?
     */
    splitAfter?: boolean,
}

export type RemoveSuggestion = RemoveNodeSuggestion | RemoveTextSuggestion

/**
 * Suggest moving of a node
 */
export type MoveSuggestion = Slate.MoveNodeOperation & {
    suggestion: 'change',
    change: 'move',
    /**
     * Copy of `path`
     *
     * This field is used to detect whether this operation was inverted, in
     * which case this path will be different from `path`.
     */
    originalPath: Slate.Path,
}

export type MergeSuggestion = Slate.MergeNodeOperation & {
    suggestion: 'change',
    change: 'merge',
}

export type SplitSuggestion = Slate.SplitNodeOperation & {
    suggestion: 'change',
    change: 'split',
}

/**
 * Suggest a change to the document
 */
export type ChangeSuggestion = InsertSuggestion
    | RemoveSuggestion
    | MoveSuggestion
    | MergeSuggestion
    | SplitSuggestion

// ---------------------------------------------- inverse change suggestions ---

/**
 * Result of calling {@link Operation.inverse} on a {@link InsertSuggestion}.
 */
export type InverseInsertSuggestion = (Slate.RemoveNodeOperation | Slate.RemoveTextOperation) & {
    suggestion: 'change',
    change: 'insert',
}

/**
 * Result of calling {@link Operation.inverse} on a {@link RemoveSuggestion}.
 */
export type InverseRemoveSuggestion = (Slate.InsertNodeOperation | Slate.InsertTextOperation) & {
    suggestion: 'change',
    change: 'remove',
}

/**
 * Result of calling {@link Operation.inverse} on a {@link MergeSuggestion}.
 */
export type InverseMergeSuggestion = Slate.SplitNodeOperation & {
    suggestion: 'change',
    change: 'merge',
}

/**
 * Result of calling {@link Operation.inverse} on a {@link SplitSuggestion}.
 */
export type InverseSplitSuggestion = Slate.MergeNodeOperation & {
    suggestion: 'change',
    change: 'split',
}

/**
 * Result of calling {@link Operation.inverse} on a {@link ChangeSuggestion}.
 */
export type InverseChangeSuggestion = InverseInsertSuggestion
    | InverseRemoveSuggestion
    | InverseMergeSuggestion
    | InverseSplitSuggestion

// ------------------------------------------------------ accept suggestions ---

/**
 * Accept insertion of a node or a text fragment
 */
export type AcceptInsertSuggestion = (Slate.InsertNodeOperation | Slate.InsertTextOperation) & {
    suggestion: 'accept',
    change: 'insert',
}

/**
 * Accept removal of a node or a text fragment
 */
export type AcceptRemoveSuggestion = (Slate.RemoveNodeOperation | Slate.RemoveTextOperation) & {
    suggestion: 'accept',
    change: 'remove',
}

/**
 * Accept moving a node
 */
export type AcceptMoveSuggestion = Slate.MoveNodeOperation & {
    suggestion: 'accept',
    change: 'move',
    /**
     * Copy of `path`
     *
     * This field is used to detect whether this operation was inverted, in
     * which case this path will be different from `path`.
     */
    originalPath: Slate.Path,
}

/**
 * Accept merging two nodes
 */
export type AcceptMergeSuggestion = Slate.MergeNodeOperation & {
    suggestion: 'accept',
    change: 'merge',
}

/**
 * Accept splitting a node
 */
export type AcceptSplitSuggestion = Slate.SplitNodeOperation & {
    suggestion: 'accept',
    change: 'split',
    /**
     * Location of the node corresponding to the split-off part of the node at
     * `path`.
     *
     * The other part may have moved, so we need to save its actual location in
     * case this operation will be inverted.
     */
    otherPartPath: Slate.Path,
}

/**
 * Accept a change to the document
 */
export type AcceptSuggestion = AcceptInsertSuggestion
    | AcceptRemoveSuggestion
    | AcceptMoveSuggestion
    | AcceptMergeSuggestion
    | AcceptSplitSuggestion

// ------------------------------------------------------ reject suggestions ---

/**
 * Reject insertion of a node or a text fragment
 */
export type RejectInsertSuggestion = (Slate.InsertNodeOperation | Slate.InsertTextOperation) & {
    suggestion: 'reject',
    change: 'insert',
}

/**
 * Reject removal of a node or a text fragment
 */
export type RejectRemoveSuggestion = (Slate.RemoveNodeOperation | Slate.RemoveTextOperation) & {
    suggestion: 'reject',
    change: 'remove',
}

/**
 * Reject moving a node
 */
export type RejectMoveSuggestion = Slate.MoveNodeOperation & {
    suggestion: 'reject',
    change: 'move',
    /**
     * Copy of `path`
     *
     * This field is used to detect whether this operation was inverted, in
     * which case this path will be different from `path`.
     */
    originalPath: Slate.Path,
}

/**
 * Reject merging two nodes
 */
export type RejectMergeSuggestion = Slate.MergeNodeOperation & {
    suggestion: 'reject',
    change: 'merge',
}

/**
 * Reject splitting a node
 */
export type RejectSplitSuggestion = Slate.SplitNodeOperation & {
    suggestion: 'reject',
    change: 'split',
}

/**
 * Reject a change to the document
 */
export type RejectSuggestion = RejectInsertSuggestion
    | RejectRemoveSuggestion
    | RejectMoveSuggestion
    | RejectMergeSuggestion
    | RejectSplitSuggestion

// ---------------------------------------------- inverse accept suggestions ---

/**
 * Result of calling {@link Operation.inverse} on
 * a {@link AcceptInsertSuggestion}.
 */
export type InverseAcceptInsertSuggestion =
(Slate.RemoveNodeOperation | Slate.RemoveTextOperation) & {
    suggestion: 'accept',
    change: 'insert',
}

/**
 * Result of calling {@link Operation.inverse} on
 * a {@link AcceptRemoveSuggestion}.
 */
export type InverseAcceptRemoveSuggestion =
(Slate.InsertNodeOperation | Slate.InsertTextOperation) & {
    suggestion: 'accept',
    change: 'remove',
}

/**
 * Result of calling {@link Operation.inverse} on
 * a {@link AcceptMergeSuggestion}.
 */
export type InverseAcceptMergeSuggestion = Slate.SplitNodeOperation & {
    suggestion: 'accept',
    change: 'merge',
}

/**
 * Result of calling {@link Operation.inverse} on
 * a {@link AcceptSplitSuggestion}.
 */
export type InverseAcceptSplitSuggestion = Slate.MergeNodeOperation & {
    suggestion: 'accept',
    change: 'split',
    otherPartPath: Slate.Path,
}

/**
 * Result of calling {@link Operation.inverse} on a {@link AcceptSuggestion}.
 */
export type InverseAcceptSuggestion = InverseAcceptInsertSuggestion
    | InverseAcceptRemoveSuggestion
    | InverseAcceptMergeSuggestion
    | InverseAcceptSplitSuggestion

// ---------------------------------------------- inverse reject suggestions ---

/**
 * Result of calling {@link Operation.inverse} on
 * a {@link RejectInsertSuggestion}.
 */
export type InverseRejectInsertSuggestion =
(Slate.RemoveNodeOperation | Slate.RemoveTextOperation) & {
    suggestion: 'reject',
    change: 'insert',
}

/**
 * Result of calling {@link Operation.inverse} on
 * a {@link RejectRemoveSuggestion}.
 */
export type InverseRejectRemoveSuggestion =
(Slate.InsertNodeOperation | Slate.InsertTextOperation) & {
    suggestion: 'reject',
    change: 'remove',
}

/**
 * Result of calling {@link Operation.inverse} on
 * a {@link RejectMergeSuggestion}.
 */
export type InverseRejectMergeSuggestion = Slate.SplitNodeOperation & {
    suggestion: 'reject',
    change: 'merge',
}

/**
 * Result of calling {@link Operation.inverse} on
 * a {@link RejectSplitSuggestion}.
 */
export type InverseRejectSplitSuggestion = Slate.MergeNodeOperation & {
    suggestion: 'reject',
    change: 'split',
}

/**
 * Result of calling {@link Operation.inverse} on a {@link RejectSuggestion}.
 */
export type InverseRejectSuggestion = InverseRejectInsertSuggestion
    | InverseRejectRemoveSuggestion
    | InverseRejectMergeSuggestion
    | InverseRejectSplitSuggestion

// -------------------------------------------------------- type definitions ---

/**
 * Union containing all types representing suggestion operations
 */
export type SuggestionOperation = ChangeSuggestion
    | AcceptSuggestion
    | RejectSuggestion

/**
 * Union containing all types representing inversed suggestion operations
 *
 * These types represent results of {@link Operation.inverse} when they aren't
 * valid suggestion operations. They can be normalized back to
 * {@link SuggestionOperation} using {@link normalize}.
 */
export type InverseSuggestionOperation = InverseChangeSuggestion
    | InverseAcceptSuggestion
    | InverseRejectSuggestion

/**
 * Normal Slate operation which isn't a suggestion operation.
 *
 * This type differs from {@link Operation} in that it only allows `null` and
 * `undefined` as values of `suggestion`, thus ensuring that values of this type
 * are not suggestion operations.
 */
export type NonSuggestionOperation = Slate.Operation & {
    suggestion?: undefined | null,
    change?: undefined | null,
}

/**
 * A normal Slate operation or a non-inversed suggestion operation.
 */
export type NonInverseOperation = NonSuggestionOperation | SuggestionOperation

/**
 * Slate's {@link Operation} type extended with types for suggestion operations.
 */
export type OperationEx = NonInverseOperation | InverseSuggestionOperation

// ----------------------------------------------------------- normalization ---

function normalize(op: OperationEx): NonInverseOperation

/**
 * Normalize an inversed operation
 *
 * Due to the way in which operations are encoded, inverses of some suggestion
 * operations can't be represented as operations. Additionally, some suggestion
 * operations when inversed become {@link InverseSuggestionOperation} rather
 * than suggestion operations. This function normalizes those invalid
 * inversions.
 *
 * Inversions which can't be represented as an {@link Operation} will instead be
 * represented as a {@link RestoreSuggestion}.
 */
function normalize(op: OperationEx): NonInverseOperation {
    switch (op.type) {
    case 'insert_node':
        if (op.change !== 'remove') return op

        switch (op.suggestion) {
        case 'change': return { ...op, type: 'remove_node', suggestion: 'reject' }
        case 'reject': return { ...op, type: 'remove_node', suggestion: 'change' }
        default: throw new Error("not implemented: " + JSON.stringify(op))
        }

    case 'insert_text':
        if (op.change !== 'remove') return op

        switch (op.suggestion) {
        case 'change': return { ...op, type: 'remove_text', suggestion: 'reject' }
        case 'reject': return { ...op, type: 'remove_text', suggestion: 'change' }
        default: throw new Error("not implemented: " + JSON.stringify(op))
        }

    case 'remove_node':
        if (op.change !== 'insert') return op

        switch (op.suggestion) {
        case 'change': return { ...op, type: 'insert_node', suggestion: 'reject' }
        case 'reject': return { ...op, type: 'insert_node', suggestion: 'change' }
        default: throw new Error("not implemented: " + JSON.stringify(op))
        }

    case 'remove_text':
        if (op.change !== 'insert') return op

        switch (op.suggestion) {
        case 'change': return { ...op, type: 'insert_text', suggestion: 'reject' }
        case 'reject': return { ...op, type: 'insert_text', suggestion: 'change' }
        default: throw new Error("not implemented: " + JSON.stringify(op))
        }

    case 'move_node': {
        if (op.suggestion == null || Slate.Path.equals(op.path, op.originalPath)) return op
        const newPath = Slate.Path.isSibling(op.path, op.newPath)
            ? op.path
            : Slate.Path.transform(Slate.Path.next(op.path), op)
        if (newPath === null) throw new Error("Path is null: " + JSON.stringify(op))

        switch (op.suggestion) {
        case 'change': return { ...op, path: op.originalPath, newPath, suggestion: 'reject' }
        case 'reject': return { ...op, path: op.originalPath, newPath, suggestion: 'change' }
        default: throw new Error("not implemented: " + JSON.stringify(op))
        }
    }

    case 'merge_node': {
        if (op.change !== 'split') return op

        const path = Slate.Path.previous(op.path)

        switch (op.suggestion) {
        case 'change': return { ...op, type: 'split_node', path, suggestion: 'reject' }
        case 'reject': return { ...op, type: 'split_node', path, suggestion: 'change' }
        default: throw new Error("not implemented: " + JSON.stringify(op))
        }
    }

    case 'split_node': {
        if (op.change === 'split') return op

        const path = Slate.Path.next(op.path)

        switch (op.suggestion) {
        case 'change': return { ...op, type: 'merge_node', path, suggestion: 'reject' }
        case 'reject': return { ...op, type: 'merge_node', path, suggestion: 'change' }
        default: throw new Error("not implemented: " + JSON.stringify(op))
        }
    }

    case 'set_node':
    case 'set_selection':
        return op
    }
}

export const SuggestionOperation = {
    isSuggestionOperation(value: Slate.Operation): value is SuggestionOperation {
        if ('suggestion' in value && value.suggestion == null) return true

        switch (value.type) {
        case 'insert_node':
        case 'insert_text':
        case 'remove_node':
        case 'remove_text':
            return typeof value.suggestion === 'string'
                && typeof value.change === 'string'
                && ['change', 'accept', 'reject'].includes(value.suggestion)
                && ['insert', 'remove'].includes(value.change)

        case 'move_node':
            return typeof value.suggestion === 'string'
                && value.change === 'move'
                && ['change', 'accept', 'reject'].includes(value.suggestion)
                && Slate.Path.isPath(value.originalPath)

        case 'merge_node':
        case 'split_node':
            return typeof value.suggestion === 'string'
                && typeof value.change === 'string'
                && ['change', 'accept', 'reject'].includes(value.suggestion)
                && ['split', 'merge'].includes(value.change)

        default:
            return false
        }
    },
}

export const OperationEx = {
    normalize,
}
